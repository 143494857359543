.container {
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .feather {
    height: 16px;
    width: 16px;
    stroke: #ffffff80;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: #191B1F;
    margin: 6px 6px 0px 0px;
  }

  .feather :hover {  
    fill: #424346;
  }

  .feather-arrow {
    height: 16px;
    width: 16px;

    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    margin: 5px 0px 0px -2px;
  }


  .tooltip {
    font: Helvetica, sans-serif;
    font-size: 15px;
    line-height: 1.4;
    max-width: 280px;
    background-color: #191B1F;
    border: 1px solid #4A505A;
    color: white;
    padding: 12px;
    border-radius: 15px;
    animation: opacity 0.25s linear;
  }
  
  .arrow {
    width: 0.5rem;
    height: 0.5rem;
  }
  
  .tooltip[data-popper-placement^="right"] > .arrow {
    left: 0px;
  }
  
  .tooltip[data-popper-placement^="left"] > .arrow {
    right: 0px;
  }
  
  .tooltip[data-popper-placement^="top"] > .arrow {
    bottom: 0px;
  }
  
  .tooltip[data-popper-placement^="bottom"] > .arrow {
    top: 0px;
  }
  
  .arrow::before {
    content: "";
    background: #191B1F;
    width: 0.5rem;
    height: 0.5rem;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
  }
  
  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .tooltiplink a {
    color: #5A94E7;
    text-decoration: none;
  }


  .tooltiplink a :hover {
    color: #80b2f3;
  }
  